<template>
  <div>
    <v-card class="ma-2">
      <div class="d-flex justify-space-between px-4">
        <v-card-title>Permissions</v-card-title>
        <v-btn class=" my-auto primary" @click="openDialog()">Add permission</v-btn>
      </div>
    </v-card>
    <v-card class="ma-2">
      <v-container>
        <v-row>
          <v-col>
            <v-select
                v-model="moduleSelect"
                :items="selectItem"
                item-value="id"
                item-text="name"
                solo style="width: 250px"
                label="FIlter by ModuleId"
                transition="scale-transition"
                @change="getList"
            >
            </v-select>
          </v-col>

        </v-row>
      </v-container>
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="dataList"
            :items-per-page="5"
            hide-default-footer
        >
          <!-- <template v-slot:item.actions="{item}">
            <v-btn icon color="error" v-if="!item.isDeleted">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template> -->

          <template v-slot:item.keyword="{item}">
            <router-link :to="{name: 'permissionView', params: {id: item.id}}">{{ item.keyword }}</router-link>
          </template>

          <template v-slot:item.actions="{item}">
            <v-icon @click="toggleActivation(item.id)"
                    class="cursor-pointer pa-0"
                    color="orange"
            >
              {{ item.isDeleted ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off' }}
            </v-icon>
            <v-icon @click="getPermission(item)"
                    class="cursor-pointer ml-4"
                    style="color: #397dfb">mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!--  modals-->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span v-if="update" class="headline">Add permissions</span>
          <span v-else class="headline">Edit permissions</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Keyword"
                    outlined
                    v-model="permission.keyword"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    label="Name"
                    outlined
                    v-model="permission.name"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                    v-model="permission.moduleId"
                    :items="selectItem"
                    item-value="id"
                    item-text="name"
                    class="ma-0 pa-0"
                    solo style="width: 500px"
                    label="Module"
                    transition="scale-transition"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col>
              <v-btn @click="closeDialog" style="width:100%" color="error">
                close
              </v-btn>
            </v-col>
            <v-col>
              <v-btn v-if="update"
                     @click="addPermission()" style="width:100%" color="success">
                save
              </v-btn>
              <v-btn v-else
                     @click="savePermission()" style="width:100%" color="warning">
                edit
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
  name: "PermissionList",
  data() {

    return {
      headers: [
        {text: 'ID', value: 'id', align: 'center', sortable: false},
        {text: 'Keyword', value: 'keyword', align: 'center', sortable: false},
        {text: 'Name', value: 'name', align: 'center', sortable: false},
        {text: 'Actions', value: 'actions', sortable: false},
        {text: 'moduleId', value: 'moduleId', sortable: false},
      ],
      selectItem: [],
      moduleSelect: '',
      dataList: [],
      permission: {
        id: 0,
        keyword: '',
        name: '',
        moduleId: ''
      },
      dialog: false,
      update: false,
    }
  },
  methods: {
    openDialog() {
      this.update = true,
          this.setEmptyPermission()
      this.dialog = true
    },
    async addPermission() {
      this.update = false
      try {
        const res = await this.$http.post(`Permission/upsertPermission`, this.permission);
        if (!res.error) {
          this.successNotification();
          this.getList()
          this.dialog = false;
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.hideLoader();
      }
    },
    closeDialog() {
      this.dialog = false
      this.setEmptyPermission()
    },
    setEmptyPermission() {
      this.permission.id = 0
      this.permission.keyword = ''
      this.permission.name = ''
      this.permission.moduleId = ''
    },
    getPermission(item) {
      this.permission.id = item.id
      this.permission.keyword = item.keyword
      this.permission.name = item.name
      this.permission.moduleId = item.moduleId
      this.dialog = true
      this.update = false
    },

    async savePermission() {
      this.showLoader();
      try {
        const res = await this.$http.post(`Permission/upsertPermission`, this.permission);
        if (!res.error) {
          this.successNotification();
          this.dataList = res.result.permissions
          this.dialog = false;
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.hideLoader();
      }
    },
    async toggleActivation(id) {
      try {
        const res = await this.$http.get(`Permission/togglePermissionActivation?permissionId=${id}`);
        if (!res.error) {
          this.successNotification();
          console.log(res.result)
          this.dataList = res.result.permissions
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    getList() {
      this.$http.get(`Permission/getPermissions?moduleId=${this.moduleSelect}`).then((res) => {
        // console.log(res.result.permissions)
        this.dataList = res.result.permissions
      })
          .catch((e) => {
            console.log(e)
          });
    }
  },
  created() {
    this.setEmptyPermission()
    this.$http.get(`Module/getModules`).then((res) => {
      if (res && res.result && res.result.modules && res.result.modules.length > 0) {
        this.selectItem = res.result.modules
        this.moduleSelect = res.result.modules[0].id
        // console.log(res.result.modules[0].id);
        this.getList()
      }
    })
        .catch((e) => {
          console.log(e)
        });
  }
}
</script>

<style scoped>

</style>
